<!--
 * @Author: 墨凡 oncwnuN3VU3V55c1lqO_6llf5DtU@git.weixin.qq.com
 * @Date: 2022-08-19 16:24:50
 * @LastEditors: zll zll1217168026@163.com
 * @LastEditTime: 2022-10-09 09:17:07
 * @FilePath: \ding-lims\src\views\Statistics\DataLists.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="datalists">
    <van-nav-bar
      title="系统日志"
      left-text="返回"
      left-arrow   
      @click-left="toHome" 
    />
    <ve-table class="item"
        :columns="proColumns"
        :table-data="logList"
        :border-around="true"
        :border-x="true"
        :border-y="true"
        :cell-span-option="cellSpanOption"
        scrollWidth="1500px"
      />
      <van-pagination class="mt10" v-model="page.currentPage" :total-items="page.total" :show-page-size="5" force-ellipses v-if="page.show" @change='pageChange'>
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }" force-ellipses>{{ text }}</template>
      </van-pagination>
  </div>
  
</template>

<script>
import Vue from 'vue'
import { Grid,Pagination ,NavBar} from 'vant'
Vue.use(Grid)
Vue.use(Pagination)
Vue.use(NavBar)

export default {
  name: 'DataLists',
  components: {
  },
  data() {
    return {
      params: {
        uid: '', // 当前登录用户ID        
        type: '2',
        page: 1,
        offset: 10
      },
      page: {
        show: true,
        currentPage: 1,
        total: 0,
        page: 1,
        offset: 10,
      }, 
      count: 0,     
      proColumns: [
          { field: "create_time", key: "a", title: "操作时间", align: "center", width:'200px' },
          { field: "username", key: "b", title: "操作人名称", align: "center", width:'100px'},
          { field: "content", key: "c", title: "操作内容", align: "left",width:'600px' },          
          { field: "duration", key: "d", title: "操作时长", align: "center" },
          { field: "type", key: "e", title: "操作类型", align: "center" },          
          { field: "operate_ip", key: "f", title: "操作IP", align: "center" }
        ],
      logList: [],
      cellSpanOption: {
          bodyCellSpan: this.bodyCellSpan,
      },
    }
  },
  created() {
    this.getLog()
  },
  methods: {
    getLog(){   
      this.$get('log/get-log-list', 'api', {
        ...this.params,
        ...this.page
      }).then(res => {
        var data = res.data.list
        data.forEach(ele => {
          ele.username = ele.user.username
          ele.duration = this.formatSeconds(ele.duration)
          ele.type = '操作'
        });
        this.page.total = res.data.count
        this.logList = data

      })
    },
    pageChange(val) {         
      this.page.page = val
      this.getLog()
    },
    formatSeconds (value) {
      const result = parseInt(value)
      const h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
      const m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
      const s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
      let res = ''
      if (h !== '00') res += `${h}` + '时'
      if (m !== '00') res += `${m}` + '分'
      res += `${s}` + '秒'
      return res
    },
    toHome() {
      this.$router.push({
        path: '/home'     
      })
    }
  }
}
</script>

<style>
</style>
